<template>
  <el-container class="cashbook-table-holder">
    <el-header v-if="showHeader">
      <el-row>
        <el-col :span="10" class="header-holder">
          <slot name="header" />
        </el-col>
        <el-col :span="14">
          <div v-if="paging" class="page-holder">
            <span v-if="totalCount" class="object-count">
              {{ localPageSize * (currentPage - 1) + page.length }}
              {{ $t('components.th_datatable.pagination.of') }}
              {{ totalCount }}
              {{ $t('components.th_datatable.pagination.items') }}
            </span>

            <el-pagination
              :current-page.sync="currentPage"
              :page-sizes="pageSizes"
              :page-size="localPageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </el-header>

    <el-main class="table-holder">
      <el-table
        ref="table"
        :data="page"
        style="width: 100%"
        height="100%"
        class="table"
        :class="{ 'disable-header': !showTableHeader }"
        :cell-style="cellStyle"
        @row-click="handleRowClick"
      >
        <el-table-column v-if="$slots.expandable" type="expand">
          <template #default="props">
            <slot name="expandable" :data="props" />
          </template>
        </el-table-column>

        <el-table-column
          v-for="(header, index) in headers"
          :key="index"
          :label="header.label"
          :width="header.width"
          :formatter="header.formatter"
          :show-overflow-tooltip="header.truncate === true"
          :prop="header.prop"
          :align="header.align || 'left'"
        />
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true
    },

    tableData: {
      type: Array,
      required: true
    },
    /**
     * Define whether to page or not (not in use)
     */
    paging: {
      type: Boolean,
      required: false,
      default: true
    },
    showTableHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    cellStyle: {
      type: Function,
      required: false,
      default: undefined
    },
    /**
     * Define a page size
     */
    pageSize: {
      type: Number,
      required: false,
      default: 20
    },
    /**
     * Define available page sizes the user can choose from
     */
    pageSizes: {
      type: Array,
      required: false,
      default: function () {
        return [5, 20, 50, 100]
      },
      validator: function (value) {
        let valid = true
        value.forEach((item) => {
          if (!Number.isFinite(item)) valid = false
        })

        return valid
      }
    }
  },
  data() {
    const pageSize = this.pageSize
    return {
      localData: this.tableData,
      currentPage: 1,
      localPageSize: pageSize || 20,
      page: []
    }
  },
  computed: {
    totalCount() {
      return this.tableData.length
    }
  },
  created() {
    this.handlePage()
  },
  methods: {
    /**
     *
     * will be emitted from the pagination component. Any size change will cause the table to return to page 1
     * @param {Number} val page size that was defined in the page size options
     */
    handleSizeChange(val) {
      this.localPageSize = val
      this.handlePage()
      this.currentPage = 1
      // order of execution might be important here, as we allow outside pageSize changes
      this.$emit('size-changed', val)
    },
    /**
     *
     * any page control will trigger this event, e.g. a paging number or a goto input
     * @param val page index
     */
    handleCurrentChange(val) {
      this.$emit('will-handle-page-change', val)
      this.handlePage()
      this.$emit('current-page-changed', val)
    },
    /**
     *
     * Slice local data based on current page and the page size. This function also handles boundaries and behaviors
     * when one is overstepped.
     */
    handlePage() {
      const notifPayload = {
        currentPage: this.currentPage,
        pageSize: this.localPageSize
      }
      this.$emit('will-handle-page', notifPayload)

      const localTransform = function localTransform(data) {
        return data
      }
      // slice from start of page
      this.page = localTransform(
        this.localData.slice(
          (this.currentPage - 1) * this.localPageSize,
          this.currentPage * this.localPageSize
        )
      )

      this.$emit('page-handled', notifPayload)
    },
    handleRowClick(row, column, event) {
      if (this.$slots.expandable) {
        this.$refs.table.toggleRowExpansion(row)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.cashbook-table-holder {
  height: 100%;
  border: 1px solid #e9eaeb;
  box-sizing: border-box;
  border-radius: 5px;
}

.table-holder {
  padding-top: 0;
  padding-bottom: 0;
}

.table-holder :deep(.el-container) {
  border: 0 none;
}

.header-holder {
  overflow: hidden;
}

.page-holder {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table :deep(.el-table__expanded-cell) {
  margin: 0;
  padding: 0;
}

.table :deep(.el-table__row td) {
  border-left: 0 none;
  border-right: 0 none;
}

.disable-header :deep(.el-table__header-wrapper) {
  display: none;
}

.object-count {
  font-size: 0.9rem;
  color: #53565c;
}
</style>
