<template>
  <div class="cash-book-payload">
    <el-row class="overview" :gutter="20">
      <el-col :span="8" class="cash-book-holder">
        <el-container class="cash-book-container bg-white">
          <el-header>
            <h3>
              <span v-text="$t('pages.cash_book.cash_book.title')" />
              <th-popover
                class="ml-2 inline-block"
                :text="$t('pages.cash_book.cash_book.message')"
                placement="bottom-start"
              />
            </h3>
          </el-header>
          <el-main class="cash-book-data-holder">
            <el-row class="cash-book-single-row">
              <span
                v-text="
                  $t('pages.cash_book.cash_book.property.start_float.label')
                "
              />
              <span
                v-text="
                  formatCashBookItem(cashBook.start_float, cashBook.currency)
                "
              />
            </el-row>
            <el-row class="cash-book-single-row">
              <span
                v-text="
                  $t('pages.cash_book.cash_book.property.end_amount.label')
                "
              />
              <span
                v-text="
                  formatCashBookItem(cashBook.end_amount, cashBook.currency)
                "
              />
            </el-row>
            <el-row class="cash-book-multi-row">
              <span
                v-text="$t('pages.cash_book.cash_book.property.cash_out.label')"
              />
              <el-row class="cash-book-single-row">
                <span
                  v-text="
                    $t('pages.cash_book.cash_book.property.cash_out.bank.label')
                  "
                />
                <span
                  v-text="
                    formatCashBookItem(
                      cashBook.cash_out.bank,
                      cashBook.currency
                    )
                  "
                />
              </el-row>
              <el-row class="cash-book-single-row">
                <span
                  v-text="
                    $t('pages.cash_book.cash_book.property.cash_out.safe.label')
                  "
                />
                <span
                  v-text="
                    formatCashBookItem(
                      cashBook.cash_out.safe,
                      cashBook.currency
                    )
                  "
                />
              </el-row>
            </el-row>
            <el-row class="cash-book-single-row">
              <span
                v-text="$t('pages.cash_book.cash_book.property.cash_in.label')"
              />
              <span
                v-text="formatCashBookItem(cashBook.cash_in, cashBook.currency)"
              />
            </el-row>
            <el-row class="cash-book-single-row">
              <span
                v-text="$t('pages.cash_book.cash_book.property.cash.label')"
              />
              <span
                v-text="formatCashBookItem(cashBook.cash, cashBook.currency)"
              />
            </el-row>
            <el-row class="cash-book-single-row">
              <span
                v-text="$t('pages.cash_book.cash_book.property.credit.label')"
              />
              <span
                v-text="formatCashBookItem(cashBook.credit, cashBook.currency)"
              />
            </el-row>
          </el-main>
        </el-container>
      </el-col>
      <el-col :span="16" class="expense-deposits">
        <cash-book-table
          :headers="expenseDepositsHeaders"
          :table-data="expenseDepositsTableData"
          :cell-style="cellStyle"
          class="bg-white"
        >
          <template #header>
            <h3>
              <span v-text="$t('pages.cash_book.cash_in_out.title')" />
              <th-popover
                class="ml-2 inline-block"
                :text="$t('pages.cash_book.cash_in_out.message')"
                placement="bottom-start"
              />
            </h3>
          </template>
        </cash-book-table>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="sales">
      <el-col :span="24">
        <cash-book-table
          :headers="txHeaders"
          :table-data="txTableData"
          class="bg-white"
        >
          <template #header>
            <h3>
              <span v-text="$t('pages.cash_book.transactions.title')" />
              <th-popover
                class="ml-2 inline-block"
                :text="$t('pages.cash_book.transactions.message')"
                placement="bottom-start"
              />
            </h3>
          </template>

          <template #expandable="{ data }">
            <tx-row :data="data" />
          </template>
        </cash-book-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import CashBookTable from './table'
import TxRow from './tx-row'

export default {
  components: {
    CashBookTable,
    TxRow
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      txHeaders: [
        {
          label: this.$t(
            'pages.cash_book.sales.property.transaction_number.label'
          ),
          prop: 'transaction_number',
          truncate: true,
          width: 80
        },
        {
          label: this.$t('pages.cash_book.sales.property.time.label'),
          prop: 'time',
          truncate: true,
          width: 90,
          formatter: (row) => {
            if (!row.date) return '-'
            return this.$date.formatTime(row.date)
          }
        },
        {
          label: this.$t(
            'pages.cash_book.sales.property.revenue_account_custom_id.label'
          ),
          prop: 'revenue_account_custom_id',
          truncate: true,
          width: 120
        },
        {
          label: this.$t('pages.cash_book.sales.property.process.label'),
          prop: 'process',
          width: 120,
          truncate: true,
          formatter: (row) => {
            const options = { receipt_number: row.transaction_number }
            return {
              sale: this.$t(
                'pages.cash_book.sales.property.process.type.sale.label',
                options
              ),
              sale_cancel: this.$t(
                'pages.cash_book.sales.property.process.type.sale_cancel.label',
                options
              )
            }[row.type]
          }
        },
        {
          label: this.$t('pages.cash_book.sales.property.revenue.label'),
          prop: 'revenue',
          align: 'right',
          truncate: true,
          width: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.revenue)) return '-'
            return this.$formatCurrency(row.revenue, row.currency)
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.cash_book.sales.property.vat.label'),
          prop: 'vat_amount_total',
          align: 'right',
          truncate: true,
          width: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.vat_amount_total)) return '-'
            return this.$formatCurrency(row.vat_amount_total, row.currency)
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.cash_book.sales.property.cash_amount.label'),
          prop: 'cash_amount',
          align: 'right',
          truncate: true,
          width: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.cash_amount)) return '-'
            return this.$formatCurrency(row.cash_amount, row.currency)
          },
          sortType: 'currency'
        },
        {
          label: this.$t(
            'pages.cash_book.sales.property.non_cash_amount.label'
          ),
          prop: 'non_cash_amount',
          align: 'right',
          truncate: true,
          width: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.non_cash_amount)) return '-'
            return this.$formatCurrency(row.non_cash_amount, row.currency)
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.cash_book.sales.property.cash_total.label'),
          truncate: true,
          width: 140,
          prop: 'cash_total'
        }
      ]
    }
  },
  computed: {
    cashBook() {
      return safeGet(this.item, 'payload.cash_book') || { cash_out: {} }
    },
    txTableData() {
      return safeGet(this.item, 'payload.sales') || []
    },
    expenseDepositsTableData() {
      return safeGet(this.item, 'payload.expense_deposits') || []
    },
    expenseDepositsHeaders() {
      return [
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.transaction_number.label'
          ),
          truncate: true,
          prop: 'transaction_number',
          width: 80
        },
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.time.label'
          ),
          prop: 'time',
          truncate: true,
          width: 90,
          formatter: (row) => {
            if (!row.date) return '-'
            return this.$date.formatTime(row.date)
          }
        },
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.account_custom_id.label'
          ),
          prop: 'account_custom_id',
          truncate: true,
          width: 160
        },
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.type.label'
          ),
          prop: 'type',
          truncate: true,
          width: 90,
          formatter: (row) => this.translations[row.type]
        },
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.process.label'
          ),
          prop: 'account_name',
          truncate: true,
          width: 160,
          formatter: (row) => row?.account_name || '-'
        },
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.cash_amount.label'
          ),
          prop: 'amount_cash_change',
          truncate: true,
          width: 120,
          align: 'right',
          formatter: (row) => {
            if (!Number.isFinite(row.amount_cash_change)) return '-'
            return this.$formatCurrency(row.amount_cash_change, row.currency)
          },
          sortType: 'currency'
        },
        {
          label: this.$t(
            'pages.cash_book.expense_deposits.property.comment.label'
          ),
          prop: 'description',
          width: 120,
          truncate: true,
          formatter: (row) => row?.description || '-'
        }
      ]
    },
    translations() {
      return {
        bank: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.bank.label'
        ),
        deposit: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.deposit.label'
        ),
        expense: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.expense.label'
        ),
        safe: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.safe.label'
        ),
        safe_deposit: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.safe_deposit.label'
        ),
        safe_exepense: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.safe_exepense.label'
        ),
        tip_expense: this.$t(
          'pages.cash_book.expense_deposits.property.process.type.tip_expense.label'
        )
      }
    }
  },
  methods: {
    cellStyle({ row, column }) {
      if (column.property !== 'amount_cash_change') return undefined

      if (
        Number.isFinite(row.amount_cash_change) &&
        row.amount_cash_change < 0
      ) {
        return {
          color: '#FF3B30'
        }
      }
      return undefined
    },
    formatCashBookItem(val, currency) {
      if (!Number.isFinite(val)) return '-'
      return this.$formatCurrency(val, currency)
    }
  }
}
</script>

<style lang="css" scoped>
.cash-book-payload {
  height: 100%;
  width: 100%;
}

.overview {
  height: 50%;
  padding-bottom: 20px;
  padding-top: 5px;
  overflow: hidden;
}

.sales {
  height: 50%;
  overflow: hidden;
  padding-top: 0;
}

.expense-deposits {
  height: 100%;
}

.sales > * {
  height: 100%;
}

/* .expense-deposits > * {
  height: 100%;
} */

.cash-book-data-holder {
  height: 100%;
  overflow-y: auto;
}

.cash-book-data-holder,
.cash-book-data-holder > * {
  width: 100%;
}

.cash-book-data-holder > * {
  box-sizing: border-box;
  border-bottom: 1px solid #e9eaeb;
  padding: 0.7rem;
}

.cash-book-single-row {
  display: flex;
  /* justify-items: space-between; */
}

.cash-book-single-row > *:nth-child(2) {
  margin-left: auto;
}

.cash-book-multi-row {
  padding-bottom: 0;
}

.cash-book-multi-row > *:first-child {
  display: block;
  margin-bottom: 0.5rem;
}

.cash-book-multi-row > .cash-book-single-row {
  margin-left: 1.5rem;
  margin-bottom: 0.7rem;
}

.cash-book-holder,
.cash-book-container {
  height: 100%;
}

.cash-book-container {
  border: 1px solid #e9eaeb;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
