<template>
  <div class="pt-6 h-full">
    <div
      class="th-container bg-white shadow-md rounded border border-th-lunar-gray"
    >
      <img
        src="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/no-results.svg"
        alt="no results image"
      />
      <div class="title">
        {{ computedTitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    computedTitle() {
      // NOTE: using computed property here, as `$t` cannot be used while defining props, and we don't want to mutate it afterwards
      return this.title.length ? this.title : this.$t('common.data.no_results')
    }
  }
}
</script>

<style scoped>
.th-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin-top: 25px;
  font-size: larger;
}
</style>
