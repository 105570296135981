<template>
  <el-row class="expandable-holder">
    <el-col :span="22">
      <sub-table
        class="embedded-table"
        :headers="computedHeaders"
        :table-data="txTableData"
        :paging="false"
        :show-header="false"
        :show-table-header="false"
      />
    </el-col>
    <el-col :span="2">
      <el-dropdown @command="handleCommand">
        <el-button text>
          <el-icon class="el-icon--right"><More /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="(item, index) in dropDownItems"
              :key="index"
              :command="item.command"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-col>
  </el-row>
</template>

<script>
import Decimal from 'decimal.js-light'
import SubTable from './table'

export default {
  components: {
    SubTable
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dropDownItems: [
        {
          label: this.$t('common.interactions.buttons.goto'),
          command: 'goto'
        }
      ],
      headers: [
        {
          prop: 'transaction_number',
          width: 80 + 48
        },
        {
          prop: 'time',
          width: 90
        },
        {
          prop: 'account_custom_id',
          width: 120,
          formatter: (row) => {
            return row.account_custom_id
          }
        },
        {
          prop: 'account_items',
          width: 100,
          truncate: true,
          formatter: (row) => {
            return {
              revenue: this.$t(
                'pages.cash_book.sales.property.account_items.type.revenue.label',
                {
                  percent: Number.isFinite(row.vat_rate)
                    ? new Decimal(row.vat_rate).mul(100).toNumber()
                    : '-'
                }
              ),
              transit: this.$t(
                'pages.cash_book.sales.property.account_items.type.transit.label'
              )
            }[row.type]
          }
        },
        {
          prop: 'amount_total',
          align: 'right',
          width: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.amount_total)) return ''
            return this.$formatCurrency(row.amount_total, row.currency)
          }
        },
        {
          prop: 'vat_amount_total',
          align: 'right',
          width: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.vat_amount_total)) return '-'
            return this.$formatCurrency(row.vat_amount_total, row.currency)
          }
        },
        {
          prop: 'cash_amount',
          align: 'right',
          width: 120
        },
        {
          prop: 'non_cash_amount'
        }
      ]
    }
  },
  computed: {
    computedHeaders() {
      return this.headers
    },
    txTableData() {
      return (this.data.row.account_items || []).map((item) => {
        return item
      })
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'goto') {
        const filter = {
          receipt_number: this.data.row.transaction_number,
          branch_custom_id: this.data.row.branch_custom_id,
          register_custom_id: this.data.row.register_custom_id
        }
        const computedPath = this.$makeFilteredPath(
          '/reports/financial_accounting/transactions',
          filter
        )

        return this.$router.push(computedPath)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.embedded-table :deep(.table-holder) {
  margin: 0;
  padding: 0;
}

.embedded-table :deep(.el-table),
.embedded-table :deep(.el-table th),
.embedded-table :deep(.el-table tr) {
  background-color: unset;
}

.embedded-table
  :deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: unset !important;
}

.embedded-table {
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
