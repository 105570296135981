<template>
  <div class="header-label-holder">
    <span v-if="reportType === 'default'">
      <el-icon><Calendar /></el-icon> {{ formatDateLabel(item) }}</span
    >
    <span v-else-if="reportType === 'balance_to_balance'">
      {{ formatBalanceToBalance(item) }}</span
    >
    <span v-else>{{ '-' }}</span>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  props: {
    reportType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDateLabel(item) {
      return item.date
    },
    formatBalanceToBalance(item) {
      return `#${safeGet(
        this.item,
        'payload.resource.payload.balance_number'
      )} - ${this.$date.formatDateTime(
        safeGet(this.item, 'payload.resource.payload.date')
      )}`
    }
  }
}
</script>

<style lang="css" scoped>
.header-label-holder {
  user-select: none;
}
</style>
