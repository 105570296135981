<template>
  <th-page-wrapper>
    <el-container>
      <el-header class="p-0 h-auto">
        <filter-header
          :resource="false"
          show-filter
          :search-filters="filtersList"
          :do-route-filters="true"
          route-base="cash_book"
          @search-filter="filterChanged"
        />
      </el-header>

      <el-main v-loading="loading" class="px-6 pt-0">
        <el-tabs
          v-if="tableData && tableData.length"
          :model-value="activeKey"
          class="tab-holder"
          @tab-change="handleTabClick"
        >
          <el-tab-pane
            v-for="(item, index) in tableData"
            :key="item.key"
            :name="item.key"
            lazy
          >
            <template #label>
              <header-label :item="item" :report-type="reportType" />
            </template>
            <tab-content
              v-if="(!activeKey && index === 0) || activeKey === item.key"
              :item="item"
            />
          </el-tab-pane>
        </el-tabs>

        <no-data v-else />
      </el-main>
    </el-container>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import qs from 'qs'
import pick from 'just-pick'
import { subDays } from 'date-fns'
import TabContent from './components/tab-content'
import NoData from './components/no-data'
import HeaderLabel from './components/header-label'
import FilterHeader from '@/components/filter-header'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'

function findFirstBranchWithRegister(resources) {
  const empty = null

  if (
    !resources ||
    !Array.isArray(resources.branches) ||
    !Array.isArray(resources.registers)
  ) {
    return empty
  }

  // find a non deleted branch with at least one not deleted register
  const firstBranch = resources.branches.find((branch) => {
    return (
      branch.deleted !== true &&
      !!resources.registers.find(
        (register) => register.deleted !== true && register.branch === branch.id
      )
    )
  })
  if (!firstBranch) return empty

  const firstRegister = resources.registers
    .filter((item) => item.branch === firstBranch.id)
    .sort((a, b) => a.register_number > b.register_number)[0]
  if (!firstRegister) return empty

  return {
    branch_custom_id: firstBranch.branch_number,
    register_custom_id: firstRegister.register_number
  }
}

export default {
  name: 'ReportsFinancialAccountingCashBook',

  metaInfo() {
    return {
      title: this.$t('pages.cash_book.title')
    }
  },

  components: {
    TabContent,
    NoData,
    HeaderLabel,
    FilterHeader
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'branch_group'
    ])
    this.$emit('route-filter', {
      ...elegibleObj
    })

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedBranch: null,
      loading: false,
      localActiveKey: null,
      tableData: null,
      filterForm: {},
      reportType: 'balance_to_balance'
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      branchNumber: 'Config/getCurrentBranchNumber',
      timezone: 'Config/getTimeZone',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    filtersList() {
      return [
        {
          name: 'branch_custom_id',
          type: 'remote-search-select',
          useOld: true,
          label: this.$t('pages.reports.statistics.all.branch'),
          resource: 'branches',
          filterable: true,
          closable: false,
          optionsValue: 'branch_number',
          computeName: this.$formatBranch,
          disabled: !!this.branchNumber,
          formatValue: this.getBranchLabel
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'register_custom_id',
          type: 'remote-search-select',
          useOld: true,
          label: this.$t('pages.registers.all.headers.register_number'),
          resource: 'registers',
          filterable: true,
          closable: false,
          optionsValue: 'register_number',
          computeName: this.$formatRegister,
          resultFilter: this.registerFilter,
          formatValue: this.getRegisterLabel
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          label: this.$t('pages.cash_book.filters.date.label'),
          closable: false,
          noFutureDates: true,
          formatValue: (value) => {
            return this.$date.formatDateRange(value)
          },
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    localResources() {
      return {
        branches: Array.from(this.resources?.branches || []).map(
          ([, item]) => item
        ),
        registers: Array.from(this.resources?.registers || []).map(
          ([, item]) => item
        )
      }
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter
      return parsedQuery || {}
    },
    dateStart() {
      return safeGet(this.parsedQuery, 'date.start')
    },
    dateEnd() {
      return safeGet(this.parsedQuery, 'date.end')
    },
    activeKey() {
      const _activeKey = this.localActiveKey || this.$route.query.active_key
      if (_activeKey) return _activeKey
      if (!_activeKey && this.tableData && this.tableData.length)
        return this.tableData[0].key
      return undefined
    },
    selectedBranchId() {
      const branch = this.localResources.branches.find(
        (b) => b.branch_number === Number(this.selectedBranch)
      )
      return safeGet(branch, 'id')
    }
  },

  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },

  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.handleQuery()
    })

    this.handleQuery()
  },

  methods: {
    getBranchLabel(branchId) {
      const branch = this.localResources.branches.find(
        (b) => b.branch_number === Number(branchId)
      )
      if (!branch) {
        return branchId
      }
      return this.$formatBranch(branch) || branchId
    },

    getRegisterLabel(registerId) {
      const register = this.localResources.registers.find(
        (r) => r.register_number === Number(registerId)
      )
      if (!register) {
        return registerId
      }
      return this.$formatRegister(register) || registerId
    },

    handleQuery() {
      this.filterForm = this.genDefaultFilterForm()

      this.$log.debug(this.parsedQuery)

      Object.entries(this.parsedQuery || {}).forEach((item) => {
        this.filterForm[item[0]] = item[1]
      })

      if (
        this.branchNumber &&
        this.parsedQuery.branch_custom_id !== this.branchNumber
      ) {
        this.parsedQuery.branch_custom_id = this.branchNumber

        const computedPath = this.$makeFilteredPath(this.$route.path, {
          ...this.parsedQuery
        })
        this.$router.replace(computedPath)
      }

      // Branch and register must always be present
      let firstBranchAndRegisterFilter
      if (
        !safeGet(this.parsedQuery, 'branch_custom_id') ||
        !safeGet(this.parsedQuery, 'register_custom_id')
      ) {
        try {
          firstBranchAndRegisterFilter = findFirstBranchWithRegister(
            this.localResources
          )
        } catch (err) {
          this.$logException(err, { trackError: false })
        }
      }

      // Date must always be present
      let dateFilter
      if (
        !safeGet(this.parsedQuery, 'date.start') ||
        !safeGet(this.parsedQuery, 'date.end')
      ) {
        const now = new Date()
        dateFilter = {
          date: {
            end: subDays(now, 5),
            start: subDays(now, 5 + 7)
          }
        }
      }

      if (firstBranchAndRegisterFilter || dateFilter) {
        const computedPath = this.$makeFilteredPath(this.$route.path, {
          ...this.parsedQuery,
          ...firstBranchAndRegisterFilter,
          ...dateFilter
        })

        this.$log.debug('will load again with branch and register')

        this.$router.replace(computedPath)
        return
      }

      this.$log.debug('will execute with available data')

      this.selectedBranch = Number(this.parsedQuery.branch_custom_id)

      this.$nextTick(() => {
        this.fetch()
      })
    },
    registerFilter(registers) {
      return registers.filter((r) => r.branch === this.selectedBranchId)
    },
    filterChanged(a) {
      if (a.branch_custom_id) this.selectedBranch = a.branch_custom_id
    },
    handleTabClick(key) {
      this.localActiveKey = key
    },
    genDefaultFilterForm() {
      return {
        register: null,
        register_custom_id: null,
        branch_group: null,
        branch_custom_id: this.branchNumber,
        branch: this.currentLocation,
        date: {
          start: this.dateStart,
          end: this.dateEnd
        }
      }
    },
    async fetch() {
      const opts = {
        query: {
          branch_custom_id: this.filterForm.branch_custom_id,
          branch_group: this.filterForm.branch_group,
          register_custom_id: this.filterForm.register_custom_id,
          timezone: this.timezone,
          start: this.dateStart,
          end: this.dateEnd,
          type: this.reportType
        }
      }

      this.$log.debug(opts.query)

      const errMessage = this.$t('common.errors.fetch.resource.message', {
        resource: this.$t('pages.cash_book.info.title')
      })

      this.loading = true
      let response
      try {
        response = await th.analytics().cashBook().getAll(opts)
      } catch (err) {
        this.$logException(err, { message: errMessage, trackError: false })
        return
      } finally {
        this.loading = false
      }

      this.tableData = this.parseTableData(response.data)
      this.$log.debug(this.tableData)
    },
    parseTableData(data) {
      // even though we do some view data parsing here, we later decided to do tabbing solely on
      // the index as string.
      // Hence this parsing got superfluous.
      return data.map((item, index) => {
        return {
          date: safeGet(item, 'query.window.day'),
          payload: item,
          key: `${index}`
        }
      })
    }
  }
}
</script>

<style scoped>
.tab-holder {
  height: 100%;
}

.tab-holder :deep(.el-tabs__nav-wrap) {
  overflow: visible;
  margin-bottom: -1px;
}

.tab-holder :deep(.el-tabs__content) {
  height: calc(100% - 41px);
  box-sizing: border-box;
}

.tab-holder :deep(.el-tabs__item) {
  border: 0;
}

.tab-holder :deep(.el-tabs__nav) {
  border: 0;
}

.tab-holder :deep(.el-tabs__item) {
  padding: 0 !important;
  padding-right: 10px !important;
}

.tab-holder :deep(.el-tabs__header) {
  padding-bottom: 0;
  margin-bottom: 0;
  height: 41px;
  box-sizing: content-box;
}

.tab-holder :deep(.el-tab-pane) {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
}
</style>
